.iab-app-structure {
  display: flex;
  flex-direction: row;
  position: relative;

  &__filters {
    display: flex;
    justify-content: space-around;
    padding: 2em 0;
    background: white;
    z-index: 2;
  }

  &__main {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;

  }

  &__sidebar {
    min-width: $dimension-side-bar-width;
    min-height: $dimension-tool-height;
  }

  &__view {
    flex-basis: 70%;
  }
}