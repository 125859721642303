.iab-general {
  background: $purple-darkest;
  color: white;
  width: 100%;
  height: $dimension-tool-height;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__calendar {
    background-image: $calendar-icon-white;
    width: 80px;
    height: 80px;
    background-size: 80px 80px;
    color: white;
  }

  &__heading {
    padding: 30px;
    text-align: center;
  }

  &__loading {
    background-image: url('https://pbs.twimg.com/profile_images/2557848618/u9761vc0x60xe5c1pnql.gif');
    background-size: 100px 100px;
    width: 100px;
    height: 100px;
  }
}