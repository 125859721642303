.iab-item-filter {
  flex: 1;
  max-width: 170px;

  &__title {
    text-transform: uppercase;
    font-size: 0.65em;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: black;
    font-family: $font-family !important;
  }

  &--active {
    .accordion {
      &.ui.accordion.menu {
        transform: translate(-1px, -1px);
        border: 2px solid $purple-darkest;
      }
    }
  }

  &--inactive {
    pointer-events: none;

    .accordion {
      &.ui.accordion.menu {
        transform: translate(-1px, -1px);
        border: 2px solid $grey-light;
        .item {
          .title {
            color: lightgrey;
          }
        }
      }
    }

    .iab-item-filter__title {
      color: $grey-light;
    }
  }

  &__accordion {
    &.ui.accordion.menu {
      border: 1px solid $purple-darkest;
      box-shadow: none;

      &:hover {
        transform: translate(-1px, -1px);
        border: 2px solid $purple-darkest;
      }
      .item {
        .title {
          font-family: $font-family !important;
          font-size: $font-size-filter;
          > .dropdown {
            &.icon {
              margin-top: -5px;
            }
          }
        }

        .content {
          &.active {
            position: absolute;
            background: white;
            padding: 0 1.2em 0.5em 1.2em;
            margin: 0.5em 0 0 -1.18em;
            z-index: 10;
            border-radius: 0 0 .28571429rem .28571429rem;
            width: 14.9rem;
            box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
          }
        }
      }
    }

    &__toggle-all {
      .ui.checkbox {
        label {
          &::before, &::after {
            display: none !important;
          }
        }
      }
    }
  }
}