// Fonts
$font-family: 'Noto Sans KR';
$font-size-filter: 0.9em;
// Dimensions
$dimension-side-bar-width: 220px;
$dimension-tool-height: 620px;

// Padding
$filter-padding: .92857143em 1.14285714em;

// Colours
$grey-lightest: #f5f5f5;
$grey-light: #e5e5e5;
$purple-light: #cacae4;
$purple-dark: #4f54b6;
$purple-darkest: #101028;