.iab-chart {
  &__paper {
    &.MuiPaper-root {
      background-color: $purple-darkest;
      color: white;
      border-radius: 0;
      box-shadow: none;
      height: $dimension-tool-height;

      .Component-root-30 {
        height: $dimension-tool-height !important;
      }

      #top-container {
        .MuiTypography-root {
          font-family: $font-family !important;
        }
      }
      #center-axis-container text,
      #bottom-axis-container text {
        fill: white;
        font-family: $font-family !important;
      }

      #bottom-container {
        .MuiList-root {
          .MuiListItem-root {
            .MuiListItemText-root {
              .MuiTypography-root {
                font-family: $font-family !important;
              }
            }
          }
        }
      }
    }
  }
}

// #root .MuiButtonBase-root {
//   color: white;
// }

// #center-axis-container text,
// #bottom-axis-container text {
//   fill: white;
// }

// .date-picker .MuiFormControl-root label, .date-picker .MuiInputBase-root input  {
//   color: white;
// }

// .MuiFormControl-root .MuiInput-underline:before {
//   border-bottom: 1px solid white;
// }

// .MuiFormControl-root .MuiInput-underline:hover:not(.Mui-disabled):before {
//   border-bottom: 2px solid white;
// }

// .controls {
//   display: none;
// }

// .MuiFormControl-root label, .MuiSelect-icon, label + .MuiInput-formControl {
//   color: white;
// }