.iab-by-catch {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 1em;

  &__logo {
    margin-left: 20px;
    background: url('../../assets/img/catch-white.png');
    background-size: 40px 40px;
    width: 40px;
    height: 40px;
  }
}