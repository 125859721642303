body, html {
  margin: 0;
  padding: 0;
  border-radius: 0;
  font-family: $font-family;
}

@import './structure';
@import './fonts';
@import './icons';

// .flex {
//   display: flex;
// }

// .flex--center {
//   justify-content: center;
// }