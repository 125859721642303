/*@import "../../font-awesome/css/font-awesome.min.css";*/
.iab-date-picker {
  position: relative;
  outline: none;
  flex: 1;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  background-clip: padding-box;
  line-height: 1.5;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 2px solid $purple-darkest;
  transform: translate(-1px, -1px);

  &--active {
    transform: translate(-1px, -1px);
    border-color: $purple-darkest;
  }

  &:hover {
    color: #40a9ff;
    background-color: #fff;
    border-color: $purple-darkest;
  }

  &__title {
    position: absolute;
    width: 100%;
    z-index: 1;
    padding: $filter-padding;
    pointer-events: none;
    padding-top: 1.3em;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .picker-panel {
    position: absolute;
    top: 32px;
    width: 240px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: white;
    transition: all 0.3s;
    z-index: 2;
    height: auto;
  }

  /* INPUT */

  .input-wrapper {
    position: relative;
    height: 100%;
    min-width: 85px;

    .input-icon {
      position: absolute;
      width: 14px;
      height: 14px;
      top: 50%;
      right: 14px;
      transform: translate(0, -50%);
      color: rgba(0, 0, 0, 0.25);
      cursor: pointer;
      transition: all 2s;
      background-image: $calendar-icon;
      background-size: 14px 14px;
    }

    .input-icon:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .input-icon-close {
      display: none;
    }
  }

  .year-input {
    border: 0;
    width: 100%;
    cursor: pointer;
    outline: 0;
    color: rgba(0, 0, 0, 0.65);
    padding: 0 10px;
    border-radius: 4px;
    box-sizing: border-box;
    height: 54px;
    padding-top: 15px;
    color: black;
    font-size: $font-size-filter;
  }

  .header {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .current-year {
    font-weight: bold;
    display: inline-block;
    color: rgba(0, 0, 0, 0.85);
  }

  .nav-button {
    position: absolute;
    background-color: transparent;
    border: none;
    transition: color 0.3s;
    color: rgba(0, 0, 0, 0.45);
    padding-bottom: 0;
    padding-top: 10px;

    * {
      line-height: 40px;
    }

    &-next {
      right: 15px;
    }

    &-back {
      left: 15px;
    }

    &:hover {
      color: #1890ff;
    }
  }



  .jump-button-next {
    right: 7px;
  }

  .jump-button-back {
    left: 7px;
  }



  /* BODY */
  .body {
    border-top: 1px solid #e8e8e8;
  }

  .year-wrapper {
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    font-weight: bold;
    width: 33.3%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .year {
    display: inline-block;
    padding: 2px;
    border-radius: 4px;
  }

  .selected-year {
    background: #1890ff;
    color: #fff;
    border: 1px solid transparent;
  }

  /* FOOTER */
  a {
    cursor: pointer;
  }

  .footer {
    border-top: 1px solid #e8e8e8;
    line-height: 38px;
    padding: 0 12px;
  }

  .footer-btn {
    text-align: center;
    display: block;
  }

  .footer-today {
    display: inline-block;
    text-align: center;
    margin: 0;
    color: #1890ff;
    transition: color 0.3s ease;
  }

}
