.iab-sidebar-selector {
  background: $grey-lightest;
  font-family: $font-family;

  &__heading {
    font-size: 1.2em;
    padding: 1.4em 20px;
    color: black;
    background: $grey-lightest;
  }

  &.accordion {
    height: calc(100% - 70px);
  }

  .title, &__item {
    position: relative;
    padding: 0.5em !important;
    font-family: $font-family !important;

    &.active {
      background: $grey-light;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100px;
        left: -100px;
        background: $grey-light;
      }
    }
  }

  &__item {
    cursor: pointer;
    margin-left: 0 !important;

    &.active {
      background: $purple-light;
      color: $purple-dark !important;

      &:before {
        background: $purple-light;
      }
    }
  }

  .content {
    padding: 0 !important;
    font-family: $font-family;
  }
}