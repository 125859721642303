// All font files should be referenced here
// ---

@font-face {
  font-family: $font-family;
  @import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR&display=swap');
  //@import url('https://fonts.googleapis.com/css?family=Baloo+2&display=swap');
  // src: url('../fonts/SourceSansPro-Semibold.woff2') format('woff2'),
  //   url('../fonts/SourceSansPro-Semibold.woff') format('woff');
  // font-weight: $font-weight-semibold;
  // font-style: normal;
}

@font-face {
  font-family: $font-family;
  @import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:700&display=swap');
  font-weight: 'bold';
}
