.iab-export {
  position: absolute;
  left: 0;
  width: $dimension-side-bar-width;
  bottom: 0;
  background: red;
  display: flex;
  justify-content: center;
  align-items:center;

  span {
    text-transform: uppercase;
    font-family: $font-family !important;
  }

  button {
    color: white;
    margin-left: 10px;

    .MuiIconButton-label {
      height: 0;
    }

    svg {
      width: 1.5em;
      height: 1.5em;
    }
  }
}