.iab-toggle-total {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
  border: 2px solid grey;
  margin-left: 10px;
  border-radius: 22.5px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .iab-toggle-total__slider {
      background-color: $purple-dark;
    }

    &:focus + .iab-toggle-total__slider {
      box-shadow: 0 0 1px #2196F3;
    }

    &:checked + .iab-toggle-total__slider:before {
      transform: translateX(23px);
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $purple-light;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;

    &::before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 2px;
      bottom: 1px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }

  &__wrapper {
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    &--inactive {
      pointer-events: none;

      .iab-toggle-total {

        &__slider {
          background: $grey-light;
        }
        input {
          &:checked + .iab-toggle-total__slider {
            background-color: $grey-light;
          }
        }
      }

      p {
        color: grey;
      }
    }

    p {
      margin: 0;
    }
  }
}
